/* eslint-disable prettier/prettier */
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { App, Button, ConfigProvider, Drawer, Layout, Modal } from 'antd';
import jwt_decode from 'jwt-decode';
import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import packageJson from '../package.json';
import './App.scss';
import { AppContext, InitialState } from './auth';
import './auth/axios';

import Loader from './components/common/Loader';
import MainLoader from './components/common/MainLoader';
import { config } from './utils/apiPaths';
import {
  analytics,
  appCheck,
  auth,
  functions,
  logInWithEmailAndPassword,
  logout,
  perf,
  registerWithEmailAndPassword,
  signInWithGoogle,
  storage,
  storageRef,
} from './utils/firebase/firebaseIndex';
import { isPublicRoute } from './utils/publicRoutes';

import useDocumentTitle from '@careerflow/common-components/dist/components/ResumeBuilder/CustomHooks/useDynamicTitle';
import { useAiPopupStore } from '@careerflow/common-components/dist/components/ResumeBuilder/store/resumeAiPopup';
import {
  RESET_RESUME_SETTING,
  useResumeSettingsStore,
} from '@careerflow/common-components/dist/components/ResumeBuilder/store/resumeSettings';
import InitService, {
  APP,
} from '@careerflow/common-components/dist/services/InitService';
import {
  analyticsEvent,
  initializeAnalytics,
} from '@careerflow/common-components/dist/utils/Analytics';

import bg from './locales/bg/translation.json';
import cs from './locales/cs/translation.json';
import da from './locales/da/translation.json';
import de from './locales/de/translation.json';
import en from './locales/en/translation.json';
import es from './locales/es/translation.json';
import et from './locales/et/translation.json';
import fi from './locales/fi/translation.json';
import fr from './locales/fr/translation.json';
import hi from './locales/hi/translation.json';
import hr from './locales/hr/translation.json';
import hu from './locales/hu/translation.json';
import it from './locales/it/translation.json';
import lt from './locales/lt/translation.json';
import lv from './locales/lv/translation.json';
import nl from './locales/nl/translation.json';
import no from './locales/no/translation.json';
import pl from './locales/pl/translation.json';
import pt from './locales/pt/translation.json';
import ro from './locales/ro/translation.json';
import sk from './locales/sk/translation.json';
import sl from './locales/sl/translation.json';
import sr from './locales/sr/translation.json';
import sv from './locales/sv/translation.json';
import zh from './locales/zh/translation.json';

import { initDB } from 'react-indexed-db-hook';
import SkipLink from './components/common/SkipLink';
import { InstituteFeatureFlags } from './constants/instituteFeatureToggles';
import Navbar from './components/layout/Navbar/Navbar';
import { retryDynamicImport } from './utils/retryDynamicImport';
import jobTrackerService from './services/api/JobTrackerServices';
import PaymentPlans from './components/features/PaymentPlans/PaymentsPlanV2';
import Features from './components/features/Onboarding/NewOnboarding/Features';
import SideNav from './components/layout/SideNav/SideNav';
import PersonalBrandStatement from './components/features/AITools/PersonalBrandStatement';
import JobOutreachEmail from './components/features/AITools/EmailWriter';
import globalTheme from './globalTheme';
import { sendMessage } from './utils/chrome';

const DBConfig = {
  name: 'CareerflowDB',
  version: 1,
  objectStoresMeta: [
    {
      store: 'resume',
      storeConfig: { keyPath: 'name', autoIncrement: true },
      storeSchema: [
        { name: 'name', keypath: 'name', options: { unique: true } },
      ],
    },
    {
      store: 'resumeBuilder',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }],
    },
  ],
};

try {
  initDB(DBConfig);
} catch (err) {
  console.log('Error while initializing indexed DB');
}

const LinkedinHeadlineGenerator = retryDynamicImport(
  () => import('./components/features/AITools/LinkedinHeadlineGenerator')
);

const LinkedinAboutGenerator = retryDynamicImport(
  () => import('./components/features/AITools/LinkedinAboutGenerator')
);

const PostGenerator = retryDynamicImport(
  () => import('./components/features/AITools/PostGenerator')
);

const ProfileCompletionCert = retryDynamicImport(
  () => import('./pages/ProfileCompletionCert')
);

const OnboardingQuestions = retryDynamicImport(
  () =>
    import('./components/features/Onboarding/Questionnaire/OnboardingQuestions')
);

const CoverLetter = retryDynamicImport(
  () => import('./components/features/AITools/CoverLetter')
);

const CompanySize = retryDynamicImport(
  () => import('./components/features/Onboarding/Questionnaire/CompanySize')
);
const ExpectedSalary = retryDynamicImport(
  () => import('./components/features/Onboarding/Questionnaire/ExpectedSalary')
);
const Industries = retryDynamicImport(
  () => import('./components/features/Onboarding/Questionnaire/Industries')
);
const JobSearchStatus = retryDynamicImport(
  () => import('./components/features/Onboarding/Questionnaire/JobSearchStatus')
);
const LevelOfRoles = retryDynamicImport(
  () => import('./components/features/Onboarding/Questionnaire/LevelOfRoles')
);
const QuesSecondPage = retryDynamicImport(
  () => import('./components/features/Onboarding/Questionnaire/QuesSecondPage')
);
const RolesInterested = retryDynamicImport(
  () => import('./components/features/Onboarding/Questionnaire/RolesInterested')
);
const WorkLocation = retryDynamicImport(
  () => import('./components/features/Onboarding/Questionnaire/WorkLocation')
);
const Onboarding = retryDynamicImport(
  () =>
    import('./components/features/Onboarding/NewOnboarding/page2/Onboarding')
);
const AuthScreen = retryDynamicImport(
  () => import('./pages/Signup/AuthScreen')
);

const JobTracker = retryDynamicImport(
  () => import('./components/features/JobTracker/JobTracker')
);
const ProfilePage = retryDynamicImport(
  () => import('./pages/ProfilePage/ProfilePage')
);
const Setting = retryDynamicImport(() => import('./pages/Setting/Setting'));

const ContactList = retryDynamicImport(
  () => import('./components/features/Contacts/ContactList')
);

const ExtensionLogin = retryDynamicImport(
  () => import('./pages/ExtensionLogin/ExtensionLogin')
);

const StudentGoals = retryDynamicImport(
  () => import('./components/features/Goals/StudentGoals')
);

const StudentGoalDetails = retryDynamicImport(
  () => import('./components/features/Goals/StudentGoalsDetails')
);

const Jobs = retryDynamicImport(() => import('./pages/Jobs'));

const Linkedin = retryDynamicImport(
  () => import('./components/features/Linkedin')
);

const Logout = retryDynamicImport(() => import('./pages/Logout/Logout'));

const ResumeWalkthrough = retryDynamicImport(
  () => import('./pages/ResumeWalkthrough')
);

const NewOnboarding = retryDynamicImport(
  () => import('./components/features/Onboarding/NewOnboarding')
);

const SelectPlan = retryDynamicImport(
  () => import('./components/features/Onboarding/NewOnboarding/Plan/Plans')
);

const FirstPageInfo = retryDynamicImport(
  () => import('./components/features/Onboarding/OnboardingInfo/FirstPageInfo')
);

const LastPageInfo = retryDynamicImport(
  () => import('./components/features/Onboarding/OnboardingInfo/LastPageInfo')
);

const QuesFirstPage = retryDynamicImport(
  () => import('./components/features/Onboarding/Questionnaire/QuesFirstPage')
);

const ThanksUpgrade = retryDynamicImport(
  () => import('./pages/ThanksUpgrade/ThanksUpgrade')
);

const Dashboard = retryDynamicImport(() => import('./pages/Dashboard'));

const AcceptInviteBanner = retryDynamicImport(
  () => import('./components/features/AcceptInviteBanner')
);

const AIMockInterview = retryDynamicImport(
  () => import('pages/AIMockInterview')
);

const ElevatorPitch = retryDynamicImport(
  () => import('./components/features/AITools/ElevatorPitch')
);

const InstituteOverview = retryDynamicImport(
  () => import('./components/features/InstituteOverview/InstituteOverview')
);

const ResumeBuilderList = retryDynamicImport(
  () => import('./pages/ResumeBuilderList')
);

const InterviewScreen = retryDynamicImport(
  () => import('./pages/InterviewScreen')
);

const ResumeBuilder = retryDynamicImport(() => import('./pages/ResumeBuilder'));

const Documents = retryDynamicImport(() => import('./pages/Documents'));

const Resources = retryDynamicImport(() => import('./pages/Resources'));

function CFApp() {
  const {
    userInfo,
    loading,
    isMobile,
    setUserInfo,
    upgrade,
    setUpgrade,
    setIsExtensionInstalled,
    user,
    institute,
    whiteLabeledInstitute,
    showEmailVerificationModal,
    setShowEmailVerificationModal,
  } = useContext(AppContext) as InitialState;

  const { Header, Content, Sider } = Layout;
  const [showFeatures, setShowFeatures] = useState(false);
  const [paymentPageVisited, setPaymentPageVisited] = useState(false);
  const exponentialRef = React.useRef(500);
  const [featureMode, setFeatureMode] = useState(false);
  const [isMenuFolded, setIsMenuFolded] = useState(isMobile);
  const [boardLoading, setBoardLoading] = useState(false);
  const [board, setBoard] = React.useState<any>();
  const [sectionList, setSectionList] = useState<Array<any>>([]);
  const [deletedJobList, setDeletedJobList] = useState<Array<any>>([]);
  const [documentTitle, setDocumentTitle] = useState('');
  const [favicon, setFavicon] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const setResumeSettings = useResumeSettingsStore(
    (state) => state.setResumeSettings
  );

  const { setAiPopupVisible } = useAiPopupStore();

  useDocumentTitle(documentTitle, favicon, previewUrl);

  useEffect(() => {
    let titlePrefix = '';
    if (whiteLabeledInstitute) {
      console.log(institute?.whiteLabelPageHeaderCustomization);
      titlePrefix = institute?.whiteLabelPageHeaderCustomization?.titlePrefix
        ? `${institute?.whiteLabelPageHeaderCustomization?.titlePrefix} | `
        : '';
      setFavicon(
        institute?.whiteLabelPageHeaderCustomization?.favicon
          ? institute?.whiteLabelPageHeaderCustomization?.favicon
          : ''
      );
      setPreviewUrl(
        institute?.whiteLabelPageHeaderCustomization?.previewImageUrl
          ? institute?.whiteLabelPageHeaderCustomization?.previewImageUrl
          : ''
      );
    }
    if (location.pathname.includes('/resumebuilder/editor')) {
      setDocumentTitle(titlePrefix + 'Resume Builder');
    } else if (location.pathname.includes('/resumebuilder')) {
      setDocumentTitle(titlePrefix + 'Resumes List');
    } else if (location.pathname.includes('/dashboard')) {
      setDocumentTitle(titlePrefix + 'Welcome to Dashboard');
    } else if (location.pathname.includes('/board')) {
      setDocumentTitle(
        titlePrefix + 'Job Tracker | Track and manage job applications'
      );
    } else if (location.pathname.includes('/contact')) {
      setDocumentTitle(
        titlePrefix +
          'Contact and Networking | Save contacts, schedule followups and more'
      );
    } else if (location.pathname.includes('/documents')) {
      setDocumentTitle(
        titlePrefix +
          'Document Manager | Add, View, Download and organize documents'
      );
    } else if (location.pathname.includes('/cover-letter')) {
      setDocumentTitle(
        titlePrefix + 'AI Cover letters personalized for job and your profile'
      );
    } else if (location.pathname.includes('/post-generator')) {
      setDocumentTitle(
        titlePrefix + 'Generate social media or LinkedIn Posts powered'
      );
    } else if (location.pathname.includes('/linkedin-headline')) {
      setDocumentTitle(titlePrefix + 'LinkedIn Headline Generator');
    } else if (location.pathname.includes('/linkedin-about')) {
      setDocumentTitle(
        titlePrefix + 'LinkedIn Profile About Section Generator'
      );
    } else if (location.pathname.includes('/linkedin')) {
      setDocumentTitle(
        titlePrefix +
          'LinkedIn Optimization | View Suggestions and improve your LinkedIn profile'
      );
    } else if (location.pathname.includes('/elevator-pitch')) {
      setDocumentTitle(titlePrefix + 'Elevator Pitch');
    } else {
      setDocumentTitle(
        titlePrefix +
          'AI powered Job Applications, Resume Builder, Cover letter, LinkedIn optimization and more'
      );
    }
  }, [
    location,
    whiteLabeledInstitute,
    institute?.whiteLabelPageHeaderCustomization,
  ]);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    //@ts-expect-error @ts-ignore
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'anr89zpe',
      name: user.displayName, // Full name
      email: user.email, // the email for your user
      created_at: user?.metadata?.createdAt?.slice(0, -3),
      user_id: user.uid,
    });

    if (institute?.disabledFeatures?.includes(InstituteFeatureFlags.intercom)) {
      //@ts-expect-error @ts-ignore
      window.Intercom('shutdown');
    }
  }, [user, institute]);

  React.useEffect(() => {
    if (process.env.VITE_APP_SENTRY_DSN) {
      Sentry.init({
        dsn: process.env.VITE_APP_SENTRY_DSN,
        integrations: [new BrowserTracing()],
        release: packageJson.version,
        tracesSampleRate: 0,
        sampleRate: 0.75,
        environment: process.env.VITE_APP_ENVIRONMENT,
      });
      const originalConsoleError = console.error;

      console.error = function (...args) {
        Sentry.captureException(new Error(args.join(' ')));
        originalConsoleError.apply(console, args);
      };

      if (userInfo.email) {
        Sentry.setUser({
          email: userInfo.email,
        });
      }
    }
  }, [userInfo.email]);

  React.useEffect(() => {
    if (upgrade) {
      analyticsEvent('Open Upgrade to premium modal');
    }
  }, [upgrade]);

  React.useEffect(() => {
    if (!isMobile && isMenuFolded) {
      setIsMenuFolded(false);
    }
  }, [isMenuFolded, isMobile]);

  React.useEffect(() => {
    if (user && !user?.emailVerified && userInfo.created > 1694802600000) {
      setShowEmailVerificationModal(true);
    }

    initializeAnalytics(
      userInfo.id,
      {
        userType: userInfo.userType || 'basic',
        signupSource: localStorage.getItem('signupSource'),
        email: userInfo.email,
      },
      {
        amplitudeKey: process.env.VITE_APP_AMPLITUDE_KEY,
        gaKey: process.env.VITE_APP_ANALYTICS_MEASUREMENT_ID,
      }
    );
    analyticsEvent('initialize');
  }, [
    userInfo.id,
    userInfo.signupSource,
    userInfo.userType,
    userInfo.created,
    userInfo.email,
    user?.emailVerified,
    user,
    setShowEmailVerificationModal,
  ]);

  React.useEffect(() => {
    if (window.location.pathname === '/logout') {
      localStorage.setItem('logout', 'true');
    }
    sendMessage(process.env.VITE_APP_EXTENSION_ID, { ping: true }, (res) => {
      if (res) setIsExtensionInstalled(true);
    });
    if (window.location.pathname === '/uninstall-redirect') {
      setTimeout(() => {
        analyticsEvent('Uninstall Extension');
        window.open(
          'https://docs.google.com/forms/d/1WLsY2WRC3SfJTtFZGbdTzlxR1erSiQQuSSk5A6gRUeA',
          '_self'
        );
      }, 1000);
    }

    sendMessage(process.env.VITE_APP_EXTENSION_ID, { ping: true }, (res) => {
      if (res) setIsExtensionInstalled(true);
    });

    if (localStorage.getItem('logout')) logout();
    const queryParams = new URLSearchParams(window.location.search);
    const invitedBy = queryParams.get('invite');
    const referal = queryParams.get('referalId');
    const priceId = queryParams.get('priceId');
    const coupon = queryParams.get('coupon');
    if (invitedBy) {
      localStorage.setItem('invitedBy', invitedBy as string);
    }
    if (referal) {
      localStorage.setItem('referalId', referal as string);
    }
    if (priceId) {
      localStorage.setItem('priceId', priceId as string);
    }
    if (coupon) {
      localStorage.setItem('coupon', coupon as string);
    }
  }, [setIsExtensionInstalled, user]);

  const getBoard = React.useCallback((callback?: any) => {
    setBoardLoading(true);
    jobTrackerService
      .getJobBoard()
      .then((res) => {
        setBoard(res);
        setSectionList(res.sectionList);
        setDeletedJobList(res?.deletedJobCardList || []);
        if (callback) callback(res);
      })
      .finally(() => setBoardLoading(false));
  }, []);

  const forceTokenRefresh = useCallback(async () => {
    if (user) {
      try {
        const idToken = await user.getIdToken(true);

        const decodedToken: any = jwt_decode(idToken);
        if (
          userInfo.userType !== 'premium' &&
          decodedToken.userType === 'premium'
        ) {
          exponentialRef.current = 1200001;
          setUserInfo((userInfo: any) => ({
            ...userInfo,
            userType: decodedToken.userType || 'basic',
          }));
        }
      } catch (error) {
        console.log('Error refreshing token:', error);
      }
    }
  }, [setUserInfo, user, userInfo.userType]);

  useEffect(() => {
    if (
      (userInfo?.userType === 'premium' || userInfo?.premiumUser) &&
      window?.location?.pathname?.includes('/plan')
    ) {
      navigate('/dashboard');
      setUpgrade(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const handleClearResumeSettingsStore = () => {
    setResumeSettings(RESET_RESUME_SETTING);

    setAiPopupVisible(false);
  };

  const tryRefreshToken = useCallback(() => {
    setTimeout(() => {
      if (exponentialRef.current > 1200000) return;
      exponentialRef.current = exponentialRef.current + 1000;
      forceTokenRefresh();
    }, exponentialRef.current);
  }, [forceTokenRefresh]);

  React.useEffect(() => {
    if (paymentPageVisited) {
      tryRefreshToken();
    }
  }, [paymentPageVisited, tryRefreshToken]);

  React.useEffect(() => {
    // TODO add logic to check the timestamp of jobSaved and only call getBoard on tab focus
    setInterval(() => {
      const jobSavedTimestamp = localStorage.getItem('jobSaved');
      if (!jobSavedTimestamp) return;
      const difference =
        Math.abs(Date.now() - Number.parseInt(jobSavedTimestamp)) / 1000;
      if (difference > 2) {
        // assuming it would take 2 seconds to save the job
        getBoard();
        localStorage.removeItem('jobSaved');
      }
    }, 4000);
  }, [getBoard]);

  useEffect(() => {
    InitService.init({
      baseURL: config.baseUrl || '',
      pythonBaseUrl: config.pythonBaseUrl || '',
      oneClickBaseUrl: config.oneClickBaseUrl || '',
      appContext: AppContext,
      firebaseMethods: {
        analytics,
        appCheck,
        auth,
        functions,
        logInWithEmailAndPassword,
        logout,
        perf,
        registerWithEmailAndPassword,
        signInWithGoogle,
        storage,
        triggerResetEmail: () => null,
        storageRef,
      },
      app: APP.CF_WEBAPP,
      studentContext: undefined,
      locale: {
        en,
        bg,
        cs,
        da,
        de,
        es,
        et,
        fi,
        fr,
        hi,
        hr,
        hu,
        it,
        lt,
        lv,
        nl,
        no,
        pl,
        pt,
        ro,
        sk,
        sl,
        sr,
        sv,
        zh,
      } as any,
    });
  }, []);

  if (loading) return <MainLoader />;

  return (
    <ConfigProvider theme={globalTheme}>
      <App>
        <SkipLink className="skip-link" skipTo="main">
          <Button type="default" size="small">
            Skip Navigation
          </Button>
        </SkipLink>
        <Suspense fallback={<MainLoader />}>
          {/* <Main /> */}
          <Features
            open={showFeatures}
            setOpen={setShowFeatures}
            featureMode={featureMode}
            setFeatureMode={setFeatureMode}
          />
          <Modal
            style={{ top: '2rem' }}
            width={window.innerWidth > 1200 ? '70%' : '90%'}
            bodyStyle={{
              padding: 0,
              borderRadius: '2rem',
            }}
            maskTransitionName=""
            // maskStyle={{ borderRadius: '2rem' }}
            onCancel={() => {
              analyticsEvent('Close payments modal');
              setUpgrade(false);
              if (paymentPageVisited) tryRefreshToken();
            }}
            footer={null}
            open={upgrade}
          >
            <PaymentPlans
              setPaymentPageVisited={setPaymentPageVisited}
              setUpgrade={setUpgrade}
              setShowFeatures={setShowFeatures}
            />
          </Modal>

          {!loading && Object.keys(userInfo || {}).length ? (
            <>
              {
                // User logged in and verified (authorized)
                user &&
                (userInfo?.initialOnboardingDone ||
                  userInfo?.created < 1703010600000) &&
                (user.emailVerified || userInfo?.created < 1694802600000) &&
                !isPublicRoute() ? (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {!['resumebuilder/editor', '/onboarding-test'].some(
                      (item) => window.location.pathname.includes(item)
                    ) ? (
                      <Header style={{ zIndex: '1000' }}>
                        <Navbar
                          isMenuUnfolded={isMenuFolded}
                          setIsMenuUnfolded={setIsMenuFolded}
                          setUpgrade={setUpgrade}
                        />
                      </Header>
                    ) : null}

                    {isMobile &&
                    !['resumebuilder/editor', '/onboarding-test'].some((item) =>
                      window.location.pathname.includes(item)
                    ) ? (
                      <Drawer
                        className="mobile-drawer"
                        placement="left"
                        size={'default'}
                        onClose={() => setIsMenuFolded(true)}
                        open={!isMenuFolded}
                        closable={true}
                        width={'210px'}
                      >
                        <Sider
                          hidden={window.location.pathname.includes(
                            '/resumebuilder/editor'
                          )}
                          style={{
                            height: 'calc(100vh - 50px)',
                            position: 'fixed',
                            zIndex: 2,
                          }}
                          width={210}
                          className="site-layout-background"
                        >
                          <SideNav setIsMenuFolded={setIsMenuFolded} />
                        </Sider>
                      </Drawer>
                    ) : (
                      !isMenuFolded && (
                        <Sider
                          hidden={[
                            'resumebuilder/editor',
                            '/onboarding-test',
                          ].some((item) =>
                            window.location.pathname.includes(item)
                          )}
                          style={{
                            height: 'calc(100vh - 50px)',
                            top: '50px',
                            position: 'fixed',
                            zIndex: 2,
                          }}
                          width={210}
                          className="site-layout-background"
                        >
                          <SideNav />
                        </Sider>
                      )
                    )}

                    <div>
                      <Content
                        className="site-layout-background"
                        style={{
                          margin: 0,
                          minHeight: '90vh',
                          backgroundColor: '#f8fbfe',
                          position: 'absolute',
                          left: isMobile
                            ? '0'
                            : isMenuFolded ||
                                [
                                  'resumebuilder/editor',
                                  '/onboarding-test',
                                ].some((item) =>
                                  window.location.pathname.includes(item)
                                )
                              ? '0'
                              : '210px',
                          width: ![
                            'resumebuilder/editor',
                            '/onboarding-test',
                          ].some((item) =>
                            window.location.pathname.includes(item)
                          )
                            ? isMobile
                              ? '100%'
                              : 'calc(100% - 210px)'
                            : '100%',
                        }}
                      >
                        <AcceptInviteBanner />
                        <Suspense fallback={<Loader />}>
                          <Routes>
                            <Route
                              path="/"
                              element={<Navigate to="/dashboard" replace />}
                            />

                            <Route
                              path="/extension/login"
                              element={
                                <ExtensionLogin
                                  showEmailVerificationModal={
                                    showEmailVerificationModal
                                  }
                                  setShowEmailVerificationModal={
                                    setShowEmailVerificationModal
                                  }
                                />
                              }
                            />
                            <Route
                              path="/board"
                              element={
                                <JobTracker
                                  board={board}
                                  boardLoading={boardLoading}
                                  sectionList={sectionList}
                                  setSectionList={setSectionList}
                                  deletedJobList={deletedJobList}
                                  getBoard={getBoard}
                                />
                              }
                            />

                            <Route
                              path="/mock-interview"
                              element={<AIMockInterview />}
                            />

                            <Route
                              path="/elevator-pitch"
                              element={<ElevatorPitch />}
                            />
                            <Route
                              path="/dashboard"
                              element={
                                <Dashboard
                                  setFeatureMode={setFeatureMode}
                                  setOpenFeatures={setShowFeatures}
                                />
                              }
                            />
                            <Route
                              path="/resumebuilder"
                              element={
                                <ResumeBuilderList
                                  handleClearResumeSettingsStore={
                                    handleClearResumeSettingsStore
                                  }
                                />
                              }
                            />
                            <Route
                              path="/resumebuilder/editor"
                              element={
                                <ResumeBuilder
                                  handleClearResumeSettingsStore={
                                    handleClearResumeSettingsStore
                                  }
                                />
                              }
                            />
                            <Route
                              path="/cover-letter"
                              element={
                                <CoverLetter
                                  upgrade={upgrade}
                                  setUpgrade={setUpgrade}
                                />
                              }
                            />
                            <Route
                              path="/post-generator"
                              element={
                                <PostGenerator
                                  upgrade={upgrade}
                                  setUpgrade={setUpgrade}
                                />
                              }
                            />
                            <Route
                              path="/linkedin-about"
                              element={
                                <LinkedinAboutGenerator
                                  upgrade={upgrade}
                                  setUpgrade={setUpgrade}
                                />
                              }
                            />
                            <Route
                              path="/linkedin-headline"
                              element={
                                <LinkedinHeadlineGenerator
                                  upgrade={upgrade}
                                  setUpgrade={setUpgrade}
                                />
                              }
                            />
                            <Route
                              path="/brand-statement"
                              element={
                                <PersonalBrandStatement
                                  upgrade={upgrade}
                                  setUpgrade={setUpgrade}
                                />
                              }
                            />
                            <Route
                              path="/job-outreach-email-writer"
                              element={
                                <JobOutreachEmail
                                  upgrade={upgrade}
                                  setUpgrade={setUpgrade}
                                />
                              }
                            />
                            <Route path="/profile" element={<ProfilePage />} />
                            <Route path="/settings" element={<Setting />} />
                            <Route path="/contact" element={<ContactList />} />
                            <Route path="/documents" element={<Documents />} />
                            <Route path="/linkedin" element={<Linkedin />} />
                            <Route
                              path={`/organization/goals`}
                              element={<StudentGoals />}
                            />
                            <Route
                              path={`/organization/goals/:id`}
                              element={<StudentGoalDetails />}
                            />
                            <Route
                              path={`/organization/:instituteName/jobs/:jobId`}
                              element={
                                <Jobs
                                  board={board}
                                  sectionList={sectionList}
                                  getBoard={getBoard}
                                />
                              }
                            />
                            <Route
                              path={`/organization/jobs`}
                              element={
                                <Jobs
                                  board={board}
                                  sectionList={sectionList}
                                  getBoard={getBoard}
                                />
                              }
                            />
                            <Route
                              path={`/organization/overview`}
                              element={<InstituteOverview />}
                            />
                            <Route
                              path={`/organization/resources`}
                              element={<Resources />}
                            />
                            <Route
                              path="/onboarding-test"
                              element={<NewOnboarding />}
                            />
                            <Route
                              path="*"
                              element={<Navigate to="/dashboard" replace />}
                            />
                            <Route
                              path="/interview-screen"
                              element={<InterviewScreen />}
                            />
                          </Routes>
                        </Suspense>
                      </Content>
                    </div>
                  </div>
                ) : (
                  <Suspense fallback={<MainLoader />}>
                    <Routes>
                      <Route
                        path="/"
                        element={<Navigate to="/dashboard" replace />}
                      />
                      <Route
                        path="/certificate/:certificateId"
                        element={<ProfileCompletionCert />}
                      />
                      <Route
                        path="/extension/login"
                        element={
                          <ExtensionLogin
                            showEmailVerificationModal={
                              showEmailVerificationModal
                            }
                            setShowEmailVerificationModal={
                              setShowEmailVerificationModal
                            }
                          />
                        }
                      />
                      <Route
                        path="/login"
                        element={
                          <AuthScreen
                            showEmailVerificationModal={
                              showEmailVerificationModal
                            }
                            setShowEmailVerificationModal={
                              setShowEmailVerificationModal
                            }
                          />
                        }
                      />
                      <Route
                        path="/signup"
                        element={
                          <AuthScreen
                            showEmailVerificationModal={
                              showEmailVerificationModal
                            }
                            setShowEmailVerificationModal={
                              setShowEmailVerificationModal
                            }
                          />
                        }
                      />
                      <Route path="/logout" element={<Logout />} />
                      <Route path="/walkthrough" element={<NewOnboarding />} />

                      <Route
                        path="/resume-walkthrough"
                        element={
                          <ResumeWalkthrough
                            showEmailVerificationModal={
                              showEmailVerificationModal
                            }
                            setShowEmailVerificationModal={
                              setShowEmailVerificationModal
                            }
                          />
                        }
                      />
                      <Route
                        path="/plan"
                        element={
                          <SelectPlan
                            setUpgrade={setUpgrade}
                            setShowFeatures={setShowFeatures}
                          />
                        }
                      />
                      <Route path="/thanks" element={<ThanksUpgrade />} />
                      <Route
                        path="onboarding-questions"
                        element={<OnboardingQuestions />}
                      >
                        <Route
                          path="onboarding-start"
                          element={<FirstPageInfo />}
                        />
                        <Route path="0" element={<Onboarding />} />
                        <Route path="1" element={<QuesSecondPage />} />
                        <Route path="2" element={<QuesFirstPage />} />
                        <Route path="3" element={<RolesInterested />} />
                        <Route path="4" element={<WorkLocation />} />
                        <Route path="5" element={<LevelOfRoles />} />
                        <Route path="6" element={<CompanySize />} />
                        <Route path="7" element={<Industries />} />
                        <Route path="8" element={<ExpectedSalary />} />
                        <Route path="9" element={<JobSearchStatus />} />
                        <Route
                          path="onboarding-end"
                          element={<LastPageInfo />}
                        />
                      </Route>
                    </Routes>
                  </Suspense>
                )
              }
            </>
          ) : (
            <MainLoader />
          )}
        </Suspense>
      </App>
    </ConfigProvider>
  );
}

export default CFApp;
